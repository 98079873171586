<template>
    
    <div class="memory-detail-page">
        <full-loading ref="fullLoading" :title="`查询中...`"></full-loading>
        <page-title :showBack="false" :title="info.name||''" :title-edit="$checkPermission('memory:memoryStoreRouter:rename')" @saveTitle="saveTitleEvent" style="border: none;" v-if="info">
            <template slot="info">
                <div class="more-info">
                    <el-popover trigger="hover">
                        <el-form label-width="80px" size="mini" class="more-info-popover">
                            <el-form-item label="语言方向" v-if="LANGUAGE_DICT">
                                {{LANGUAGE_DICT[info.sourceLang].name}} <i class="el-icon-arrow-right"></i> {{LANGUAGE_DICT[info.targetLang].name}}
                            </el-form-item>
                            <el-form-item label="条数">{{info.nums}} 条</el-form-item>
                            <el-form-item label="创建时间">{{info.createTime}}</el-form-item>
                        </el-form>
                        <svg-icon name="ib-info" class-name="color-555" slot="reference"></svg-icon>
                    </el-popover>
                </div>
            </template>
            <template slot="tools">
                <div style="width:190px;">
                    <el-input placeholder="输入翻译记忆原文或译文" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable 
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <el-button size="small" :disabled="tableSelection.length < 1" plain round icon="el-icon-delete" v-permission="`memory:memoryStoreRouter:delete`" @click="e=>deleteBtnEvent(e)" style="margin-left:10px;">删除</el-button>
                <el-button type="primary" size="small" round icon="el-icon-upload2" v-permission="`memory:memoryStore:detaiRouter:upload`" @click="upload.show = true" style="margin-left:10px;">上传记忆库</el-button>
                <el-dropdown  @command="downloadFileEvent">
                    <el-button type="primary" round size="small" style="margin-left:10px;">
                        下载 <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="excel" v-permission="`memory:memoryStore:detaiRouter:downloadExcel`">下载Excel</el-dropdown-item>
                        <el-dropdown-item command="tmx" v-permission="`memory:memoryStore:detaiRouter:downloadTMX`">下载TMX</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </template>
        </page-title>
        <el-table :data="list" ref="tableList" :cell-class-name="tableRowClassName" @selection-change="handleTableSelectionChange" @row-click="handleTableRowClick" style="width: 100%" class="table-list">
            <el-table-column type="selection" :selectable="(row,index)=>{return index !== 0}"></el-table-column>
            <el-table-column width="50" type="index" label="序号" :index="tableIndexMethod"></el-table-column>
            <el-table-column :label="`原文（${info && LANGUAGE_DICT && LANGUAGE_DICT[info.sourceLang] && LANGUAGE_DICT[info.sourceLang].name}）`" prop="source">
                <template slot-scope="scope">
                    <div :lang="info && info.sourceLang" :data-id="scope.row.memDetailId">
                        <template v-if="scope.row.edit === true">
                            <el-input type="textarea" size="small" :autosize="{minRows: 2, maxRows: 6}" resize="none" maxlength="2000" show-word-limit v-model="scope.row.source" @keydown.native.enter.prevent="saveEditEvent(scope)" placeholder="输入翻译记忆原文"></el-input>
                        </template>
                        <template v-else>
                            <template v-if="scope.row.memDetailId">{{scope.row.source}}</template>
                            <template v-else>
                                <el-input type="textarea" size="small" :autosize="{minRows: 2, maxRows: 6}" resize="none" maxlength="2000" show-word-limit v-model="newSourceValue" @keydown.native.enter.prevent="saveAddEvent" placeholder="输入翻译记忆原文"></el-input>
                            </template>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="`译文（${info && LANGUAGE_DICT && LANGUAGE_DICT[info.targetLang] && LANGUAGE_DICT[info.targetLang].name}）`">
                <template slot-scope="scope">
                    <div :lang="info && info.targetLang">
                        <template v-if="scope.row.edit === true">
                            <el-input type="textarea" size="small" :autosize="{minRows: 2, maxRows: 6}" resize="none" maxlength="2000" show-word-limit v-model="scope.row.target" @keydown.native.enter.prevent="saveEditEvent(scope)" placeholder="输入翻译记忆译文"></el-input>
                        </template>
                        <template v-else>
                            <template v-if="scope.row.memDetailId">{{scope.row.target}}</template>
                            <template v-else>
                                <el-input type="textarea" size="small" :autosize="{minRows: 2, maxRows: 6}" resize="none" maxlength="2000" show-word-limit v-model="newTargetValue" @keydown.native.enter.prevent="saveAddEvent" placeholder="输入翻译记忆译文"></el-input>
                            </template>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column width="85" label="操作">
                 <template slot-scope="scope">
                     <template v-if="scope.row.edit === true">
                         <el-tooltip content="保存"><span class="table-col-icon" @click.stop="saveEditEvent(scope)"><svg-icon name="ib-check-o"></svg-icon></span></el-tooltip>
                         <el-tooltip content="删除"><span class="table-col-icon" @click.stop="e=>deleteBtnEvent(e,scope.row)"><svg-icon name="ib-trash-o"></svg-icon></span></el-tooltip>
                     </template>
                     <template v-else>
                         <template v-if="scope.row.memDetailId">
                            <el-tooltip content="编辑"><span class="table-col-icon" @click.stop="editBtnEvent(scope)"><svg-icon name="ib-edit"></svg-icon></span></el-tooltip>
                            <el-tooltip content="删除"><span class="table-col-icon" @click.stop="e=>deleteBtnEvent(e,scope.row)"><svg-icon name="ib-trash-o"></svg-icon></span></el-tooltip>
                         </template>
                         <template v-else>
                             <el-tooltip content="保存"><span class="table-col-icon" @click.stop="saveAddEvent"><svg-icon name="ib-check-o"></svg-icon></span></el-tooltip>
                         </template>
                     </template>
                     
                 </template>
            </el-table-column>
        </el-table>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :current-page="pageNumber"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog title="上传记忆库" :visible.sync="upload.show" destroy-on-close @close="uploadCloseEvent" :width="'520px'" top="20px">
            <div class="fonts-12 margin-b-16 line-height-0">
                支持xlsx/xls/tmx/sdltm，点击下载模版：<el-button type="text" class="color-link padding-0" :disabled="upload.downloadLoading" @click="downloadTemplateEvent">excel</el-button>
            </div>
            <upload-oss ref="appendUploadFileDom" :multiple="false" :accept="upload.accept" :params="{}"></upload-oss>
            <div slot="footer" class="text-center">
                <el-button round plain size="small" @click="upload.show = false">取消</el-button>
                <el-button round type="primary" size="small" :loading="upload.loading" @click="submitAppendFile">确定</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteConfirmEvent"
            @cancelEvent="deleteCancelEvent"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import $ from 'jquery';
import FullLoading from '@/components/FullLoading';
import fileDownload from 'js-file-download';
import { memoryApi, commonApi } from '@/utils/api';
import config from '@/utils/config';
import PageTitle from '@/components/PageTitle';
import AlertDialog from '@/components/AlertDialog';
import UploadOss from '@/components/UploadOss';
export default {
    components:{PageTitle, AlertDialog, FullLoading, UploadOss},
    data(){
        return {
            info: null,
            searchForm:{
                kw:'',
                sort:'1'
            },
            list:[{memDetailId:null,source:'',target:'',edit:false}],
            tableSelection:[],
            alertInfo:{
                message:'确定要删除这些翻译记忆吗？',
                type:'confirm', //alert ,confirm
                buttonName:'确认删除',
                buttonType:'danger',
            },
            pageSize:20,
            pageNumber:1,
            total:0,
            
            newSourceValue:'',
            newTargetValue:'',
            // editSourceValue:'',
            // editTargetValue:'',

            uploadBtnLoading:false,
            upload:{
                show: false,
                loading: false,
                downloadLoading: false,
                accept:'.xlsx,.xls,.tmx,.sdltm',
            },
        }
    },
    computed: {
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    created(){
        // this.info = this.$ls.get('memoryDbInfo');
        console.log(this.$route);
    },
    mounted(){
        this.initPage();
    },
    methods:{
        initPage(loading){
            let url = this.$ajax.format(memoryApi.memoryDetail,{id:this.$route.query.id});
            if(this.$route.query.dId){
                url = this.$ajax.format(memoryApi.memoryDetailLocation,{id:this.$route.query.id, dId:this.$route.query.dId});
            }
            url = `${url}?pageSize=${this.pageSize}&pageNumber=${this.pageNumber}`;
            if(this.searchForm.kw){
                url = url + `&text=${encodeURI(this.searchForm.kw)}`;
            }
            if(loading !== false){
                this.$refs.fullLoading.setShow();
            }
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = [].concat([{memDetailId:null,source:'',target:'',edit:false}],res.data.pager.records);
                    this.total = res.data.pager.total;
                    this.info = res.data.memInfo;
                    this.pageNumber = res.data.pager.pageNumber;
                    if(this.$route.query.dId) {
                        this.$nextTick(()=>{
                            this.$scrollTo($(`[data-id="${this.$route.query.dId}"]`)[0], 30, {container: '.el-main', offset: 10,});
                        });
                    }
                }
            }).finally(()=>{
                if(loading !== false){
                    this.$refs.fullLoading.setHide();
                }
            })
        },
        inputSearchEvent(value){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                this.pageNumber = 1;
                self.initPage();
            },500);
        },
        downloadTemplateEvent(){
            this.upload.downloadLoading = true;
            let downMsg = this.$message({
                message: '下载中...',
                type: 'info',
                iconClass: 'el-icon-loading',
                duration: 0,
            });
            this.$ajax.get(memoryApi.downloadTemplate).then(res=>{
                if(res.data){
                    window.open(res.data);
                }else {
                    this.$message.error('获取下载地址失败');
                }
            }).finally(()=>{
                this.upload.downloadLoading = false;
                downMsg.close();
            })
        },
        handleTableSelectionChange(val){
            this.tableSelection = val;
        },
        handleTableRowClick(row, column, event){
            if (row.memDetailId && !row.edit) {
                let index = -1;
                for (let i = 0; i < this.list.length; i++) {
                    const element = this.list[i];
                    if (element.memDetailId === row.memDetailId) {
                        index = i;
                        break;
                    }
                }
                if (index > 0) {
                    this.list.splice(index,1,{...this.list[index],edit:true});
                    setTimeout(() => {
                        $(event.target).find('textarea').focus();
                    }, 100);
                }
            }
        },
        tableIndexMethod(index) {
            if(index === 0) return '–'
            return index;
        },
        handleCurrentChange(num){
            if(this.$route.query.dId) {
                this.$router.replace({path: this.$route.path, query: {id: this.$route.query.id}})
            }
            this.pageNumber = num;
            this.initPage();
        },
        editBtnEvent(scope){
            let row=scope.row;
            this.list.splice(scope.$index,1,{...this.list[scope.$index],edit:true});
        },
        saveEditEvent(scope){
            let row=scope.row;
            if(!row.source){
                this.$message.error('翻译记忆原文不能为空');
                return;
            }
            if(!row.target){
                this.$message.error('翻译记忆译文不能为空');
                return;
            }
            let url = memoryApi.saveEditMemory;
            let postData = {
                source:row.source,
                target:row.target,
                memDetailId:scope.row.memDetailId
            }
            this.$ajax.put(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success("保存翻译记忆成功");
                    // this.editSourceValue = '';
                    // this.editTargetValue = '';
                    // this.list[scope.$index]['edit'] = false;
                    this.initPage(false);
                }
            })
        },
        saveAddEvent(){
            if(!this.newSourceValue){
                this.$message.error('翻译记忆原文不能为空');
                return;
            }
            if(!this.newTargetValue){
                this.$message.error('翻译记忆译文不能为空');
                return;
            }
            let url = memoryApi.submitAddMemory;
            let postData={
                memId: this.$route.query.id,
                source:this.newSourceValue,
                target:this.newTargetValue
            }
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success("保存翻译记忆成功");
                    this.newSourceValue = '';
                    this.newTargetValue = '';
                    this.pageNumber = 1;
                    this.initPage(false);
                }
            })

        },
        saveTitleEvent(value){
            if(!value){
                this.$message.error('记忆库名称必须填写');
                return;
            }
            let url = memoryApi.memoryDB;
            let postData={
                name:value,
                memId:this.info.memId
            };
            this.$ajax.put(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success('重命名记忆库成功');
                    this.info.name = value;
                    this.$ls.set('memoryDbInfo',this.info);
                }
            }).finally(()=>{

            })
        },
        cancelBtnEvent(scope){
            this.list.splice(scope.$index, 1, {...this.list[scope.$index], edit:false})
        },
        deleteBtnEvent(e,item){
            if(item){
                this.tableSelection = [item];
            }
            this.$refs.alertDialogDom.setShow(true);
        },
        deleteConfirmEvent(){
             let url = memoryApi.batchDeleteMemory;
            let postData=[];
            this.tableSelection.map(item=>{
                postData.push(item.memDetailId);
            })
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success('翻译记忆已删除');
                    this.tableSelection = [];
                    if(this.list.length === 2){
                        this.pageNumber = this.pageNumber > 1 ? this.pageNumber - 1 : 1;
                    }
                    this.initPage(false);
                }
            })
        },
        deleteCancelEvent(){
            this.tableSelection = [];
            this.$refs.tableList.clearSelection();
        },
        downloadFileEvent(type){
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                metadataIdList: [this.info.memId], 
                title: this.info.name,
                metadataType: 'ASSET_MEM',
                moreOptions: {
                    exportFileType: type,
                }
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                }
            }).finally(()=>{
                
            })
        },
        uploadCloseEvent(){
            this.upload.loading = false;
        },
        /**
         * 追加文件
         */
        submitAppendFile(){
            let url = memoryApi.appendMemoryFile;
            let docs = this.$refs.appendUploadFileDom.getOssFiles();
            if(docs.length === 0){
                this.$message.error('请确认已上传文件并上传成功');
                return false;
            }
            let postData = {
                id: this.$route.query.id,
                docs,
            }
            this.upload.loading = true;
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200){
                    this.upload.show = false;
                    this.$message.success('上传成功');
                    this.pageNumber = 1;
                    this.initPage();
                }
            }).finally(()=>{
                this.upload.loading = false;
            })
        },
        tableRowClassName({row, rowIndex}) {
            if(this.$route.query.dId && row.memDetailId === this.$route.query.dId) {
                return 'active-row';
            }
            return '';
        },
    }
}
</script>
<style lang="scss" scoped>
.memory-detail-page{
    // padding: 20px;
    .more-info{
        cursor: pointer;
        svg {
            font-size: 20px;
        }
    }
    .table-col-icon{
        margin-right: 15px;
        cursor: pointer;
        svg {
            font-size: 17px;
            color: #000000;
        }
        
    }
}

</style>
<style lang="scss">
.table-list{
    &.el-table{
        // background-color:inherit;
        .active-row {
            background-color: #E5F2E6 !important;
        }
    }
    &.el-table th, &.el-table td{
        padding: 10px 0;
    }
    &.el-table tr{
        background-color:inherit;
    }
}
</style>
